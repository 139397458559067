import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePromiseTracker } from 'react-promise-tracker'
import { Grid, Typography, Button, useTheme, OutlinedInput, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { ThumbDown, ThumbUp } from '@mui/icons-material'
import { fetchAssessmentSurveyQuestions, postAssessmentSurveyAnswers } from '../../redux/actions'
import { loaderArea, progressComponentTypes } from '../../utils/variables'
import { StyledLoadingIndicator } from '../../utils/custom'
import { NotificationToast } from '../tools'

const surveyQuestionTypes = {
  TEXT: 'text',
  BOOLEAN: 'boolean'
}

const AssessmentSurvey = (props) => {
  const { userAssessmentID = '', displaySurvey = false, unlockResults = () => {} } = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const { promiseInProgress } = usePromiseTracker({ area: loaderArea.GET_ASSESSMENT_SURVEY })
  const { userID } = useSelector(state => state.auth)
  const [surveyQuestions, setSurveyQuestions] = useState([])
  const [surveyResponses, setSurveyResponses] = useState({})

  // once questions have been attempted to be retrieved, set local state appropriately
  const fireQuestionFetchSuccess = (data) => {
    // if we had length return from the data we need to set the questions as they are and build a response form
    if (data?.length) {
      setSurveyQuestions(data)

      // build an object for the response form based on the question keys returned from the fetch
      const newForm = data.reduce((obj, questionObj) => {
        const { columnHeaderId } = questionObj
        obj[columnHeaderId] = ''
        return obj
      }, {})

      // set the response form
      setSurveyResponses(newForm)
    }
  }

  // if we failed to get the questions we still want to gracefully set the questions array to a blank array
  const fireQuestionFetchFailure = () => {
    setSurveyQuestions([])
    setSurveyResponses({})
    // log the failure
    console.error('Unable to fetch survey questions.')
  }

  // fetch the survey questions
  useMemo(() => {
    if (displaySurvey) {
      setSurveyResponses({})
      dispatch(fetchAssessmentSurveyQuestions({ userID, userAssessmentID }, fireQuestionFetchSuccess, fireQuestionFetchFailure))
    }
  }, [dispatch, userID, userAssessmentID, displaySurvey])

  // based on the question type determine the type of answer input to render
  const displayAnswerType = ({ columnHeaderId, questionType }) => {
    const onSurveyAnswer = (val) => setSurveyResponses({ ...surveyResponses, [columnHeaderId]: val })
    const answerValue = surveyResponses[columnHeaderId]

    switch (questionType) {
      case surveyQuestionTypes.BOOLEAN: {
        return (
          <RadioGroup row>
            {/* TRUE */}
            <FormControlLabel
              style={{ marginRight: '2rem' }}
              control={
                <Radio
                  checked={Boolean(answerValue)}
                  onClick={() => onSurveyAnswer(answerValue ? '' : true)}
                  checkedIcon={<ThumbUp style={{ fontSize: '2rem' }} />}
                  icon={<ThumbUp style={{ fontSize: '2rem' }} />}
                />
              }
            />
            {/* FALSE */}
            <FormControlLabel
              control={
                <Radio
                  checked={Boolean(answerValue !== '' && !answerValue)}
                  onClick={() => onSurveyAnswer(answerValue !== '' && !answerValue ? '' : false)}
                  checkedIcon={<ThumbDown style={{ fontSize: '2rem' }} />}
                  icon={<ThumbDown style={{ fontSize: '2rem' }} />}
                />
              }
            />
          </RadioGroup>
        )
      }
      default: return (
        <OutlinedInput
          fullWidth
          margin='dense'
          size='small'
          multiline
          rows={4}
          style={{ backgroundColor: '#FFF' }}
          value={answerValue || ''}
          onChange={(e) => onSurveyAnswer(e.target.value)}
        />
      )
    }
  }

  const resetAndUnlock = () => {
    setSurveyQuestions([])
    setSurveyResponses({})
    unlockResults()
  }

  // attempt to post results if the survey was answered, but regardless of answers or success, unlock their results.
  const postSurveyResults = async () => {
    const surveyAnswered = Boolean(Object.values(surveyResponses).some(x => (typeof x === 'boolean') || (typeof x === 'string' && x.length > 0)))
    if (surveyAnswered) {
      dispatch(postAssessmentSurveyAnswers(userID, userAssessmentID, surveyResponses))
      NotificationToast(false, 'Thank you! We appreciate your feedback')
    }
    resetAndUnlock()
  }

  return (
    <Grid container direction='column' style={{ padding: '2em', justifyContent: 'center', alignItems: 'center' }}>
      <Grid item container direction='column'>
        <Typography variant='h5' textAlign='center'>Congratulations on completing your assessment!</Typography>
        <Typography textAlign='center'>Before moving on to your full results, we would appreciate you answering a few survey questions below to let us know how we are doing.</Typography>
        <Typography gutterBottom textAlign='center'>These questions are optional, but help us ensure we are providing the most benefit to you through our services.</Typography>
        <Typography variant='body2' textAlign='center'>After filling out this quick questionnaire and/or to move on to your full results click the 'See My Results' button.</Typography>
      </Grid>
      <Grid item container direction='column' justifyContent='center' style={{ margin: '2em 0' }}>
        {promiseInProgress &&
          <StyledLoadingIndicator
            area={loaderArea.GET_ASSESSMENT_SURVEY}
            loaderType={progressComponentTypes.LINEAR_PROGRESS}
            style={{ height: 3 }}
          />}
        {!surveyQuestions.length && !promiseInProgress &&
          <Typography textAlign='center' variant='subtitle2' style={{ color: theme.palette.grey.darkGrey }}>Sorry! Our Survey Questions Are Currently Unavailable...</Typography>}
        {!promiseInProgress && surveyQuestions.map(qObj => {
          const { columnHeaderId, questionText, questionType } = qObj
          return (
            <Grid item key={columnHeaderId} style={{ marginBottom: '1em' }}>
              <Typography gutterBottom variant='subtitle1'>{questionText}</Typography>
              {displayAnswerType({ columnHeaderId, questionType })}
            </Grid>
          )
        })}
      </Grid>
      <Grid item container direction='column' style={{ margin: '0 0 1em 0' }}>
        <Button
          size='large'
          onClick={postSurveyResults}
          variant='contained'
          color='primary'
          style={{ paddingTop: '1em', paddingBottom: '1em' }}
        >
          See My Full Results
        </Button>
      </Grid>
    </Grid>
  )
}

export default AssessmentSurvey
